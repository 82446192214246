<template>
  <div>
    <optionscard :deliverParentNum="1"></optionscard>
    <breadcrumb></breadcrumb>
    <div class="width1200">
      <!-- 文章x6 -->
      <div class="middleDiv" v-if="searchList.length">
        <div
          class="article-div-left"
          v-for="item in searchList"
          :key="item.id"
          @click="goArticle(item.pid, item.id)"
        >
          <!-- 左边的图 -->
          <div class="article-left">
            <img :src="item.image" width="100%" height="100%" />
          </div>
          <!-- 右边的字 -->
          <div class="article-right">
            <!-- 文章标题和文章所属 -->
            <div class="article-one">
              {{ item.title }} ({{ item.category_name }})
            </div>
            <!-- 文章内容 -->
            <div class="article-two" v-html="item.content"></div>
            <!-- 文章时间 -->
            <div class="article-three">{{ item.issue_time }}</div>
          </div>
        </div>
      </div>
      <!-- 如果搜索不到就显示这个 -->
      <el-empty description="查无此对象" v-else></el-empty>
      <!-- 分页 -->
      <el-pagination
        class="elPagination2"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParameter.page"
        :page-size="searchParameter.paginate"
        layout="total, slot, prev, pager, next, jumper"
        :total="total"
      >
        <span class="paginationSpan"> 记录 </span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
import breadcrumb from '@/components/Breadcrumb.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb
  },
  data () {
    return {
      total: 0,
      // 存放数据
      searchList: [],
      // 数据参数
      searchParameter: {
        page: 1,
        paginate: 6,
        title: ''
      }
    }
  },
  created () {
    /* 接收search传递的 输入框内容 */
    const title = this.$route.query.title
    this.getSearchArticle(title)
  },
  methods: {
    // 跳转文章详情
    goArticle (pid, id) {
      if (pid === 1) {
        this.$router.push({ path: 'bzglsecond/bzglthird', query: { id } })
      } else {
        this.$router.push({
          path: '/fifth/dzzsfirst/dzzssecond',
          query: { id }
        })
      }
    },
    // 获取数据
    async getSearchArticle (title) {
      this.searchParameter.title = title
      const { res } = await this.$api.homepage.getSearchArticle(
        this.searchParameter
      )
      console.log('res', res)
      for (const i of res.data) {
        // 去除时间后面的时分秒
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
      }
      this.searchList = res.data
      this.total = res.total
    },
    // 每页几条
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.searchParameter.paginate = val
      this.getSearchArticle()
    },
    // 当前第几页
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.searchParameter.page = val
      this.getSearchArticle()
    }
  },
  watch: {
    '$route.query.title' () {
      // 监听路由变化  // 组件第一次进入不会执行
      console.log('id 变化了', this.$route.query.title)
      this.getSearchArticle(this.$route.query.title)
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1200px;
  margin: 0 auto;
}

.middleDiv {
  margin-top: 40px;
}

.article-div {
  display: flex;
  justify-content: space-between;
}

.article-div-left {
  display: flex;
  margin-bottom: 50px;
  cursor: pointer;
}

.article-left {
  width: 220px;
  height: 135px;
  margin-left: 15px;
}

.article-div-left:hover .article-one {
  color: #2e88e4;
}

.article-right {
  margin-left: 20px;
}

.article-two,
.article-three {
  margin-top: 20px;
}

.article-one {
  margin-top: 15px;
  font-size: 20px;
}

.article-two {
  height: 20px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; //文本不换行，这样超出一行的部分被截取，显示...
}

.article-three {
  background-color: #f7f7f7;
  width: 90px;
  padding: 0 10px;
  font-size: 14px;
}

.elPagination2 {
  margin: 40px 240px;
  text-align: left;
}

.paginationSpan {
  font-weight: normal;
  color: #606060;
}
</style>
